export class Photoshoot {
    constructor(title, url, previewImg, photos) {
        this.title = title;
        this.url = url;
        this.previewImg = previewImg;
        this.photos = photos;
    }
    // get photos() {
    //     return this._photos;
    // }
    // get title() {
    //     return this._title;
    // }
    // get url() {
    //     return this._url;
    // }
    // get previewImg() {
    //     return this._previewImg;
    // }
}